import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "font-awesome/css/font-awesome.min.css"; // Import Font Awesome CSS
import "./style.css";
import Home from "./pages/Home";
import Contact from "./pages/ContactUs";
function App() {
  return (
    <div className="App">
      <Home   />
    </div>
  );
}

export default App;