import React from "react";

const About = () => {
  return (
    <div>
      <div className="container-fluid bg-primary py-5 mb-5 hero-header">
        <div className="container py-5">
          <div className="row justify-content-center py-5">
            <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-3 text-white animated slideInDown">
                Welcome to PARTHO{" "}
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <a style={{color:'white'}} href="/">Let's Escape To The Paradise</a>
                  </li>
                  {/* <li className="breadcrumb-item">
                    <a href="#">Pages</a>
                  </li> */}
                  {/* <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    About
                  </li> */}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;